'use strict';

var processInclude = require('base/util');

function initializeIfNotGlide($slider) {
    if (!$slider.parent('.glide__track').length) {
        $slider.slick();
    }
}

function pdSlider() {

    if ($('.pd-slider.slick-initialized').length) {
        $('.pd-slider').each(function (idx, attr) {
            $(attr).slick('unslick');

            $(attr).on('init', function (event, slick) {
                setTimeout(function () {
                    $(slick.$slides).each(function (index, key) {
                        if ($(key).find('.tile-body .tile-body-footer div[data-bv-show="inline_rating"] .bv_main_container.bv_hover').length !== 0) {
                            $(key).find('.tile-body .tile-body-footer div[data-bv-show="inline_rating"] .bv_main_container.bv_hover').attr('style', 'display: flex !important; flex-direction: row; flex-wrap: wrap !important;');
                        }
                    });
                }, 4000);
            });
        });
    }

    // Detect if page in in the editor
    if (window.location !== window.parent.location) {
        $(".experience-page").addClass('in-editor');

        $('.experience-component .carousel').each(function () {
            if (!$(this).hasClass('edit-mode')) {
                initializeIfNotGlide($(this).find('.pd-slider'));
            }
        });
    } else {
        $('.experience-component .carousel').each(function () {
            initializeIfNotGlide($(this).find('.pd-slider'));
        });
    }
}

function getCustomColors() {
    let customBtn = document.querySelectorAll('.btn-custom');
    if (customBtn.length) {
        for (let b = 0; b < customBtn.length; b++) {
            customBtn[b].style.setProperty('--bgColor', customBtn[b].dataset.bgcolor);
            customBtn[b].style.setProperty('--textColor', customBtn[b].dataset.textcolor);
        }
    }
}

$(document).ready(function () {
    // processInclude(require('./experience/imageAndText'));
    // processInclude(require('./experience/featureFocus'));
    // processInclude(require('./experience/videoTile'));
    processInclude(require('./experience/spacer'));
    // Include Instagram client JS
    processInclude(require('core/components/instagram'));
    // Detect if page in in the editor
    if (window.location !== window.parent.location) {
        $('.experience-page').addClass('in-editor');

        $('.experience-component .carousel').each(function (index) {
            if (!$(this).hasClass('edit-mode')) {
                initializeIfNotGlide($(this).find('.pd-slider'));
            }
        });
    } else {
        initializeIfNotGlide($(this).find('.pd-slider'));
    }

    pdSlider();
    getCustomColors();
});
