
function setTiles(container) {
	let url = container.dataset.source,
		isCarousel = container.classList.contains('ig-gallery--carousel');
	fetch(url)
	.then(response => {
		if(response.status === 200){
		   return response.json();
		} else{
		   removeInstagramComponent();
		}
    })
	.then(data => {
		let post = data.data,
			icon = ``;
		//console.log(post);
		for (p=0; p<post.length; p++) {
			let user = post[p].username,
				id = post[p].id,
				type = post[p].media_type,
				link = post[p].permalink,
				media = post[p].media_url,
				caption = post[p].caption || '',
				thumb = type === "VIDEO" ? post[p].thumbnail_url : null;

			if (type === "CAROUSEL_ALBUM") {
				icon = `<span class="ig-tile-icon"><i class="fa fa-clone"></i></span>`;
			} else if (type === "VIDEO") {
				icon = `<span class="ig-tile-icon"><i class="fa fa-film"></i></span>`;
			} else {
				icon = ``;
			}

			container.innerHTML += `<span class="ig-tile ig-tile--${type} ${caption.length > 0 ? 'hasCaption' : ''}" data-id="${id}" data-user="${user}">${icon}<a class="ig-tile-link" href="${link}" target="_blank"><img class="ig-tile-image lazyload" src="${thumb !== null ? thumb : media}" alt="${user} - Image ${id}"/><div class="ig-tile-caption">${caption}</div></a></span>`;
		}
		// Initialize slick for carousel component(s)
		if (isCarousel) {
			initSlick(container);
		}
	});
}

function initSlick(container) {

    $(container).slick({
		centerMode: true,
		accessibility: false,
		slidesToShow: 1,
		slidesToScroll: 1,
		mobileFirst: true,
    	responsive: [{
			breakpoint: 0,
			settings: {
				slidesToShow: 1.1,
				centerMode: false,
				dots:true
			}
		},{
    			breakpoint: 480,
    			settings: {
    				slidesToShow: 1.2,
					centerMode: false,
					dots:true
    			}
    		},
    		{
    			breakpoint: 768,
    			settings: {
    				slidesToShow: 2.1,
					centerMode: false,
					dots:true
    			}
    		},
			{
    			breakpoint: 1200,
    			settings: {
    				slidesToShow: 4.1,
					centerMode: false,
					dots:true
    			}
    		},
			{
    			breakpoint: 1490,
    			settings: {
    				slidesToShow: 4.1,
					centerMode: false,
					dots:true
    			}
    		}
    	]
    })
}

module.exports = function () {
	$(document).ready(function(){
		let ig = $('.ig-gallery');

		for (i=0;i<ig.length;i++) {
			setTiles(ig[i]);
		}
	})
};
function removeInstagramComponent() {
    if ($('.experience-commerce_assets-instagram .instagram-component .ig-gallery-container .ig-gallery.ig-gallery--carousel').length) {
        var self = $('.experience-commerce_assets-instagram .instagram-component .ig-gallery-container .ig-gallery.ig-gallery--carousel');
        if(self) {
          $('.experience-commerce_assets-instagram').addClass('d-none');
        }
    }
}